import { FC, PropsWithChildren } from 'react';
import useStyles from 'src/components/LandingMozza/common/Fight/ContainerWithIrregularBackground/styles';

const ContainerWithIrregularBackground: FC<PropsWithChildren> = ({
  children,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {children}
      <svg
        width="256"
        height="44"
        viewBox="0 0 256 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.svg}
        preserveAspectRatio="none"
      >
        <path
          d="M194.183 4.39C183.068 7.28738 131.389 5.59724 106.939 4.39C84.7512 3.70009 39.212 2.00986 34.5589 0.76812C28.7425 -0.78405 1.2768 -2.33622 0.632217 21.4637C-0.0123783 45.2637 19.0518 42.1593 23.8987 42.1593C28.7455 42.1593 34.8847 39.055 36.8234 39.055C38.7622 39.055 91.7549 42.6767 96.6017 41.1245C101.448 39.5724 155.411 42.1596 167.689 43.7118C179.968 45.2639 224.559 40.09 233.607 39.0553C242.654 38.0205 255.256 40.09 255.578 17.3249C255.899 -5.44028 208.077 0.768265 194.183 4.39Z"
          fill="#E8A13B"
          fillOpacity="0.1"
        />
      </svg>
    </div>
  );
};

export default ContainerWithIrregularBackground;
