import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  container: {
    display: 'inline-block',
    position: 'relative',
    padding: `${rem(10)} ${rem(1)}`,
  },
  svg: {
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translateX(-50%)',
  },
}));
