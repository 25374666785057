import { ElementType, FC, ReactElement } from 'react';
import { Typography } from '@mui/material';

import ContainerWithIrregularBackground from '@/components/LandingMozza/common/Fight/ContainerWithIrregularBackground/ContainerWithIrregularBackground';

import useStyles from './styles';

type Props = {
  title: string | ReactElement;
  content: string | ReactElement;
  icon: ElementType;
};

const Benefit: FC<Props> = ({ title, content, icon: Icon }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Icon />
      <ContainerWithIrregularBackground>
        <Typography variant="title2Xs" component="h3" className={classes.title}>
          {title}
        </Typography>
      </ContainerWithIrregularBackground>
      <Typography component="p" className={classes.description}>
        {content}
      </Typography>
    </div>
  );
};

export default Benefit;
