import { FC } from 'react';

const EuroCurrencyIcon: FC = () => (
  <svg
    width="66"
    height="65"
    viewBox="0 0 66 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3064_17889)">
      <path
        d="M62.882 33.1844C65.7581 21.4592 57.177 9.12954 49.8682 6.25683C45.6917 4.61527 39.6356 2.23507 26.6339 2.96842C10.3819 3.88511 -0.818147 31.5214 3.53575 52.0729C7.88965 72.6245 38.4017 64.2657 48.6386 60.8338C58.8756 57.4019 59.2868 47.841 62.882 33.1844Z"
        fill="#E8A13B"
        fillOpacity="0.1"
      />
      <path
        d="M44.6817 42.3357C42.7915 44.324 40.3714 45.4191 37.8671 45.4191C33.7625 45.4191 30.2086 42.5367 28.536 38.3702H39.937C40.9257 38.3702 41.7272 37.5686 41.7272 36.58C41.7272 35.5914 40.9257 34.7898 39.937 34.7898H27.6263C27.5559 34.2045 27.5174 33.6075 27.5174 32.9996C27.5174 32.3138 27.5657 31.6416 27.6558 30.9856H39.937C40.9257 30.9856 41.7272 30.184 41.7272 29.1954C41.7272 28.2068 40.9257 27.4051 39.937 27.4051H28.6312C30.3397 23.3606 33.8369 20.58 37.8671 20.58C40.3714 20.58 42.7915 21.6752 44.6817 23.6633C45.3622 24.3794 46.4946 24.4089 47.2123 23.7278C47.9291 23.0464 47.9575 21.9133 47.277 21.1967C44.7045 18.4904 41.3627 17 37.8675 17C31.8994 17 26.7978 21.3346 24.8181 27.4056H20.0209C19.0323 27.4056 18.2307 28.2072 18.2307 29.1958C18.2307 30.1844 19.0323 30.986 20.0209 30.986H24.051C23.9785 31.6466 23.937 32.3175 23.937 33C23.937 33.6057 23.9687 34.202 24.0266 34.7902H20.0209C19.0323 34.7902 18.2307 35.5918 18.2307 36.5804C18.2307 37.5691 19.0323 38.3706 20.0209 38.3706H24.7471C26.6728 44.559 31.8259 49 37.8671 49C41.3627 49 44.7036 47.5092 47.2762 44.8033C47.957 44.0866 47.9291 42.9536 47.2119 42.2723C46.4956 41.5909 45.3622 41.6194 44.6817 42.3357Z"
        fill="#F59E0B"
      />
    </g>
    <defs>
      <clipPath id="clip0_3064_17889">
        <rect width="65" height="65" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default EuroCurrencyIcon;
