import { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import messages from 'src/components/LandingMozza/common/Fight/Header/messages';

import ArticleHeader from '@/components/LandingMozza/common/ArticleHeader/ArticleHeader';
import SectionTitle from '@/components/LandingMozza/common/SectionTitle/SectionTitle';
import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';

interface Props {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
}

const Header: FC<Props> = ({ title, subtitle }) => (
  <ArticleHeader>
    <SectionTitle>
      {title ?? (
        <FormattedMessage
          {...messages.title}
          values={{
            enter: EnterChunk,
          }}
        />
      )}
    </SectionTitle>
    <Typography component="p" variant="bodyXl">
      {subtitle ?? (
        <FormattedMessage
          {...messages.description}
          values={{
            enter: EnterChunk,
          }}
        />
      )}
    </Typography>
  </ArticleHeader>
);

export default Header;
