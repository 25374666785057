import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: rem(20),
    padding: `${rem(24)} ${rem(15)}`,
    backgroundColor: palette.supporting.amber.light,
    borderRadius: rem(16),

    [breakpoints.up('md')]: {
      width: 'unset',
    },
  },
  title: {
    textAlign: 'center',
    color: palette.supporting.amber.main,
  },
  description: {
    textAlign: 'center',
  },
}));
