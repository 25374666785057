import { FC } from 'react';

const SadIcon: FC = () => (
  <svg
    width="66"
    height="65"
    viewBox="0 0 66 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3064_17900)">
      <path
        d="M62.882 33.1844C65.7581 21.4592 57.177 9.12954 49.8682 6.25683C45.6917 4.61527 39.6356 2.23507 26.6339 2.96842C10.3819 3.88511 -0.818147 31.5214 3.53575 52.0729C7.88965 72.6245 38.4017 64.2657 48.6386 60.8338C58.8756 57.4019 59.2868 47.841 62.882 33.1844Z"
        fill="#E8A13B"
        fillOpacity="0.1"
      />
      <g clipPath="url(#clip1_3064_17900)">
        <path
          d="M33 15C23.0748 15 15 23.0748 15 33C15 42.9252 23.0748 51 33 51C42.9252 51 51 42.9252 51 33C51 23.0748 42.9253 15 33 15ZM33 47.7273C24.8794 47.7273 18.2727 41.1207 18.2727 33C18.2727 24.8793 24.8794 18.2727 33 18.2727C41.1206 18.2727 47.7273 24.8794 47.7273 33C47.7273 41.1206 41.1206 47.7273 33 47.7273Z"
          fill="#F59E0B"
        />
        <path
          d="M32.9999 34.6171C32.9998 34.6171 33 34.6171 32.9999 34.6171C30.4648 34.6171 28.0813 35.6043 26.2888 37.3969C25.6498 38.0359 25.6498 39.072 26.2888 39.7111C26.6084 40.0306 27.0272 40.1904 27.446 40.1904C27.8648 40.1904 28.2836 40.0306 28.603 39.7111C29.7775 38.5366 31.339 37.8899 32.9999 37.8899C34.6608 37.8899 36.2223 38.5367 37.3968 39.7111C38.0357 40.35 39.0719 40.35 39.7109 39.7111C40.35 39.0721 40.35 38.036 39.7109 37.3969C37.9184 35.6043 35.5351 34.6171 32.9999 34.6171Z"
          fill="#F59E0B"
        />
        <path
          d="M27.5607 31.0516C27.9676 30.6458 28.2 30.0829 28.2 29.5091C28.2 28.9353 27.9676 28.3724 27.5607 27.9664C27.1549 27.5607 26.592 27.3273 26.0182 27.3273C25.4433 27.3273 24.8815 27.5607 24.4756 27.9664C24.0698 28.3724 23.8364 28.9353 23.8364 29.5091C23.8364 30.0829 24.0698 30.6458 24.4756 31.0516C24.8815 31.4575 25.4444 31.6909 26.0182 31.6909C26.592 31.6909 27.1549 31.4575 27.5607 31.0516Z"
          fill="#F59E0B"
        />
        <path
          d="M39.9818 27.3273C39.4069 27.3273 38.8451 27.5607 38.4393 27.9664C38.0324 28.3724 37.8 28.9353 37.8 29.5091C37.8 30.0829 38.0324 30.6458 38.4393 31.0516C38.8451 31.4575 39.408 31.6909 39.9818 31.6909C40.5556 31.6909 41.1185 31.4573 41.5244 31.0516C41.9313 30.6458 42.1636 30.0829 42.1636 29.5091C42.1636 28.9353 41.9313 28.3724 41.5244 27.9664C41.1185 27.5607 40.5556 27.3273 39.9818 27.3273Z"
          fill="#F59E0B"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3064_17900">
        <rect width="65" height="65" fill="white" transform="translate(0.5)" />
      </clipPath>
      <clipPath id="clip1_3064_17900">
        <rect
          width="36"
          height="36"
          fill="white"
          transform="translate(15 15)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SadIcon;
