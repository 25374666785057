import { FC } from 'react';

const BookOpenOutlinedIcon: FC = () => (
  <svg
    width="66"
    height="65"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3064_17878)">
      <path
        d="M62.882 33.1844C65.7581 21.4592 57.177 9.12954 49.8682 6.25683C45.6917 4.61527 39.6356 2.23507 26.6339 2.96842C10.3819 3.88511 -0.818147 31.5214 3.53575 52.0729C7.88965 72.6245 38.4017 64.2657 48.6386 60.8338C58.8756 57.4019 59.2868 47.841 62.882 33.1844Z"
        fill="#E8A13B"
        fillOpacity="0.1"
      />
      <g clipPath="url(#clip1_3064_17878)">
        <path
          d="M50.0238 22.3788L47.5002 22.3786V19.7664C47.5002 19.1683 47.0627 18.6602 46.4712 18.5717C46.3679 18.5562 43.9095 18.1991 40.9491 18.7378C37.6489 19.3383 35.0169 20.8191 33.2318 23.0578C31.4466 20.8191 28.8146 19.3383 25.5145 18.7378C22.554 18.1993 20.0956 18.5562 19.9925 18.5717C19.4009 18.6603 18.9634 19.1685 18.9634 19.7664V22.3786H16.4399C15.7728 22.3786 15.2319 22.9196 15.2319 23.5868V46.3509C15.2319 46.7421 15.4212 47.109 15.7401 47.3356C16.059 47.5623 16.4679 47.6205 16.8372 47.4918C16.9279 47.4604 25.9803 44.3709 32.7305 47.4499C33.049 47.5953 33.4147 47.5953 33.7333 47.4499C40.4645 44.3797 49.5364 47.4606 49.6265 47.4918C49.756 47.5369 49.8901 47.559 50.0236 47.559C50.2713 47.559 50.5164 47.4829 50.7236 47.3358C51.0425 47.1091 51.2319 46.7422 51.2319 46.351V23.5869C51.2318 22.9197 50.6909 22.3788 50.0238 22.3788ZM17.648 44.7346V24.7948H18.9634V40.8827C18.9634 41.2337 19.117 41.5671 19.3825 41.7965C19.6481 42.026 20.0013 42.1289 20.3485 42.0776C20.4151 42.0679 25.6631 41.3474 29.4973 43.9781C24.7743 43.1704 20.0473 44.1046 17.648 44.7346ZM32.0238 42.8886C30.3351 41.3628 28.1355 40.3311 25.5145 39.8542C24.2532 39.6246 23.083 39.5577 22.1472 39.5577C21.8694 39.5577 21.6122 39.5637 21.3796 39.573V20.8757H21.3795C23.7013 20.771 29.3494 21.0192 32.0238 25.5529V42.8886ZM34.4398 25.5531C37.1046 21.0388 42.7616 20.7793 45.0841 20.8788V39.573C44.068 39.532 42.5847 39.5565 40.9491 39.8542C38.3282 40.3311 36.1285 41.3627 34.4398 42.8886V25.5531ZM36.9623 43.9788C40.797 41.3466 46.0478 42.0678 46.1132 42.0775C46.4612 42.1297 46.8142 42.0274 47.0806 41.7981C47.347 41.5685 47.5002 41.2345 47.5002 40.8827V24.7949H48.8156V44.7346C46.4156 44.1044 41.6867 43.1698 36.9623 43.9788Z"
          fill="#F59E0B"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3064_17878">
        <rect width="65" height="65" fill="white" transform="translate(0.5)" />
      </clipPath>
      <clipPath id="clip1_3064_17878">
        <rect
          width="36"
          height="36"
          fill="white"
          transform="translate(15.2319 15)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default BookOpenOutlinedIcon;
