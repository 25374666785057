import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(32),
    [breakpoints.up('md')]: {
      gap: rem(60),
    },
  },
  cardsContainer: {
    width: '100%',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    padding: `0 0 0 ${rem(16)}`,
    [breakpoints.up('md')]: {
      padding: `0 ${rem(80)}`,
    },
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gap: rem(16),
    width: `calc(4 * 90% + 3*${rem(16)})`,
    [breakpoints.up(1000)]: {
      width: '100%',
    },
  },
}));
