import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'fight.message.title',
    defaultMessage: 'Ce contre quoi <enter></enter> nous luttons ✊',
  },
  description: {
    id: 'landingStepper.message.description',
    defaultMessage:
      'Les agences de soutien scolaire classiques nous semblent dépassées, trop rigides et plus vraiment adaptées <enter></enter> aux besoins des élèves et aux budgets des parents.\u00A0🦕',
  },
});
